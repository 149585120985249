import { EnumToArray } from '../../../utils/enumToArray';

enum Size {
  DisplayLg = 'display-lg',
  DisplayMd = 'display-md',
  BigTextLg = 'big-text-lg',
  BigTextMd = 'big-text-md',
  BigTextSm = 'big-text-sm',
  BigTextXs = 'big-text-xs',
  TitleLg = 'title-lg',
  TitleMd = 'title-md',
  TitleSm = 'title-sm',
  TitleXs = 'title-xs',
  Title2xs = 'title-2xs',
  Title3xs = 'title-3xs',
  Title4xs = 'title-4xs',
  Title5xs = 'title-5xs',
  Title6xs = 'title-6xs',
  LargeLg = 'large-lg',
  LargeMd = 'large-md',
  BodyLg = 'body-lg',
  BodyMd = 'body-md',
  BodySm = 'body-sm',
  BodyXs = 'body-xs',
  Body2xs = 'body-2xs',
  SmallLg = 'small-lg',
  SmallMd = 'small-md',
  CaptionLg = 'caption-lg',
  CaptionMd = 'caption-md',
}

export const PTZTextConfigList = {
  SizeList: EnumToArray([Size]) as Size[],
};

export const PTZTextConfig = {
  Size,
};
