import { Component, Prop, Host, h } from '@stencil/core';
import { PTZTextTypes } from './types/ptz-text.types';
import { PTZTextConfig } from './types/ptz-text.enums';

@Component({
  tag: `ptz-text`,
  styleUrl: 'ptz-text.scss',
  shadow: false,
})
export class PTZText {
  /** Tamanhos de acordo com a tipografia */
  @Prop() size?: PTZTextTypes.SizeList = PTZTextConfig.Size.BodySm;

  render() {
    return (
      <Host>
        {!this.size && (
          <p>
            <slot />
          </p>
        )}

        {this.size?.includes('display') && (
          <h1 class={`ptz-font ptz-${this.size}`}>
            <slot />
          </h1>
        )}

        {this.size?.includes('big') && (
          <h2 class={`ptz-font ptz-${this.size}`}>
            <slot />
          </h2>
        )}

        {this.size?.includes('title') && (
          <h3 class={`ptz-font ptz-${this.size}`}>
            <slot />
          </h3>
        )}

        {!(this.size?.includes('display') || this.size?.includes('big') || this.size?.includes('title')) && (
          <p class={`ptz-font ptz-${this.size}`}>
            <slot />
          </p>
        )}
      </Host>
    );
  }
}
